import { Page } from '../Page';
import './MintHomePage.scss';
import '../mint-v2/components/CollectionsForm.scss';

import MintHomeLayout from './layouts/MintHomeLayout';
import { Carousel } from 'react-responsive-carousel';
import Item from './components/Item';

import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { TransitioningSection } from '@components/transitioning-section';
import { mainSuite } from '@services/ServiceFactory';
import { debug } from '@common/LogWrapper';
import ItemInfo from './components/ItemInfo';
import { HostedApp } from '@components/hosted-app/HostedApp';
import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';

import { useNavigate, useParams } from 'react-router-dom';
import debounce from '@common/Debounce';
import { PathParam } from '@common/PathParam';
import { GemzItem, getPagePath } from '@common/GemzItem';
import { useGemz } from '@hooks/useGemz';
import { GemzActionType } from '../../context/gemz/GemzActionType';
import { LoadState } from '../../common/LoadState';
import TopBar from './components/TopBar';
import { SearchParam } from '@common/SearchParam';
import { AiCreateForm } from '../create/AiCreateForm';

const log = debug('app:pages:MintHomePage');

const scrollDeltaThreshold = 10;

enum State {
  VIEWER_LOADING,
  LOADING_STORY_DATA,
  SEND_STORY_DATA_TO_GAME,
  WAIT_FOR_GAME_LOAD,
}

export function MintHomePage({ showCreate = false }: { showCreate?: boolean }) {
  const navigate = useNavigate();

  const appDispatch = useAppDispatch();

  const { gemzState, updateFeed, gemzDispatch, loadStoryFromGem, preloadStories } = useGemz();

  const appState = useAppState();

  const params = useParams();

  const [showUI, setShowUI] = useState(true);

  const [currentSlide, setCurrentSlide] = useState(0);

  const [scrollDirection, setScrollDirection] = useState(0);

  // note: debouncer takes care of this. leaving it on for reference
  // const [isChangingSlide, setIsChangingSlide] = useState(false);

  const carouselRef = useRef<Carousel>();

  const [viewerReady, setViewerReady] = useState(false);

  const [gameLoadingState, setGameLoadingState] = useState<string>('none');

  const feed = gemzState.feed;
  const [disableScroll, setDisableScroll] = useState(appState.isAiGenMode || appState.isAiForm);

  const [frontendScreenshotUrl, setFrontendScreenshotUrl] = useState<string>();

  useEffect(() => {
    if (!appState.initialSearchParams) {
      return;
    }
    if (appState.showOnboard) {
      const urlSearchParams = new URLSearchParams(appState.initialSearchParams);
      if (showCreate) {
        urlSearchParams.append(SearchParam.Redirect, `/create?${appState.initialSearchParams.toString()}`);
      } else if (params[PathParam.SalePath]) {
        urlSearchParams.append(SearchParam.Redirect, `/${params[PathParam.SalePath]}?${appState.initialSearchParams.toString()}`);
      }
      if (urlSearchParams.size) {
        navigate(`/?${urlSearchParams.toString()}`);
      } else {
        navigate('/');
      }
    }
  }, [appState.initialSearchParams, appState.showOnboard, showCreate]);

  // Load feed
  useEffect(() => {
    const fetchFeed = async () => {
      await updateFeed();
      if (params?.[PathParam.SalePath]) {
        gemzDispatch({
          type: GemzActionType.BringGemToTop,
          gemPath: `/${params[PathParam.SalePath]}`,
        });
      }
      setCurrentSlide(0);
    };

    fetchFeed();
  }, [params, updateFeed]);

  const currentFeedItem: GemzItem | undefined = feed?.[currentSlide];

  // Add viewer callback
  useEffect(() => {
    const viewerCallback = async () => {
      console.warn('Viewer is ready');
      setViewerReady(true);
    };

    const storyReadyCallback = (gemData: { imageUrl: string; name: string; id: string }) => {
      // if the carousel is scrolling the iframe should just deny the request to show the game, so iframe stays hidden
      // if the carousel is on another story, denies too
      // if the carousel in on the game spot, accepts and shows the carousel

      setGameLoadingState('init ' + gemData.id + ' / ' + currentFeedItem.id);

      // current slide info
      // console.warn('>>> currentFeedItem.gemname', currentFeedItem.gemname, '-> gemName', opts.gemName);
      if (gemData.id !== currentFeedItem.id) {
        console.error('>>> game gem does not match website item. Avoiding to display game.');
        return;
      }

      if (carouselRef.current) {
        if (carouselRef.current.state.swipeMovementStarted || carouselRef.current.state.swiping) {
          console.error('>>> carousel is swiping. Avoiding to display game.');
          return;
        }
      }

      // note: debouncer will take care of this. otherwise game will never load
      // if (isChangingSlide) {
      //   console.error('>>> carousel is changing slide. Avoiding to display game.');
      //   return;
      // }

      console.warn('>>> website - storyReady. displaying game', gemData);

      // DEBUG: generate screenshot after story is loaded
      // queueScreenshot(currentFeedItem);

      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: true,
      });

      setDisableScroll(appState.isAiGenMode || appState.isAiForm);

      setGameLoadingState('playing ' + gemData.id);
    };

    const storyEndCallback = () => {
      console.warn('>>> storyEnd');

      // --------------------------------
      // AI generation game ended
      if (appState.isAiGenMode) {
        // todo carles; navigate to feed after escaping ai generation mode
        updateFeed().then(() => {
          setDisableScroll(false);

          appDispatch({
            type: AppActionType.ToggleEnableNavbar,
            navbarEnabled: true,
          });

          appDispatch({
            type: AppActionType.UpdateIsAiGenMode,
            isAiGenMode: false,
          });

          // todo: ideally, we should move to next slide,
          // todo: but if we do not repload the page for some reason stories wont load anymore
          navigate('/');

          //  auto-navigate to next slide after we bough the story gem
          // if (currentSlide < feed.length - 1) {
          //   goToNextSlide();
          // } else {
          //   goToPrevSlide();
          // }

          return;
        });
      }

      // --------------------------------
      // Normal game ended

      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: false,
      });
      //  auto-navigate to next slide after we bough the story gem
      if (currentSlide < feed.length - 1) {
        goToNextSlide();
      } else {
        goToPrevSlide();
      }
    };

    const storyScrollCallback = async (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
      // console.warn('>>> storyScrollCallback', disableScroll);
      if (disableScroll) {
        return;
      }
      doScrollCustom(opts);
      setShowUI(true); // always show ui while scrolling
    };

    const frontendScreenshotCallback = async (screenshot: string) => {
      // note: this method will only be called if we set
      // frontendScreenshotEnabled=true in game's GemzController

      if (!screenshot) {
        return;
      }

      // For testing purposes, lets add last frontend screenshot to all slides
      setFrontendScreenshotUrl(screenshot);
    };

    // todo(Cai): we should use gem unique ids for all gem related transactions
    const buyGemCallback = async ({ story, gemData, correctChoices }) => {
      await mainSuite.navbarService.api.signSession();

      const wallet = await mainSuite.navbarService.api.getWallet();
      if (!wallet) {
        console.error(`Cannot buy gem without 'wallet'.`);
        return;
      }
      const twitter = await mainSuite.navbarService.api.twitterService.auth.get();
      if (!twitter) {
        console.error(`Cannot buy gem without 'twitter'.`);
        return;
      }
      try {
        const creatorFeed = gemzState.feed.filter((item) => item.creator_address.toLowerCase() === wallet.address.toLowerCase());

        const mintData = {
          data: {
            creatorAddress: appState.isAiGenMode ? wallet.address : currentFeedItem.creator_address,
            storyId: appState.isAiGenMode ? creatorFeed.length : currentFeedItem.story_index,
            ownerAddress: wallet.address,
            txhash: 'txhash',
            confirmed: false,
            username: twitter.handle,
            gemName: appState.isAiGenMode ? gemData.name : currentFeedItem.gemname,
            storyData: encodeURI(JSON.stringify(story)),
            // todo carles/jb: at this point, in isAiGenMode mode, the story we are creating has not been added to the feed
            story_image: appState.isAiGenMode ? '' : currentFeedItem.story_image, // appState.isAiGenMode ? frontendScreenshotUrl : currentFeedItem.story_image,
            correctChoices,
          },
        };

        const response = await fetch('https://pipeline.beta.pnk.one/gems/minted', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(mintData),
        });

        if ((await response.json()).error) {
          throw new Error('Something went wrong!');
        }

        console.warn('>>> https://pipeline.beta.pnk.one/gems/minted', response, mintData);

        // send purchase outcome back to the game/viewer
        mainSuite.navbarService.sendClientEvent('PURCHASE', 'success');
      } catch (e) {
        console.log(e);
        mainSuite.navbarService.sendClientEvent('PURCHASE', 'error');
      }
    };

    const storyShowUICallback = async (enabled: boolean) => {
      setShowUI(enabled);
    };

    const onAiCreateStoryStart = () => {
      console.warn('>>> onAiCreateStoryStart. disabling scroll');
      appDispatch({
        type: AppActionType.ToggleEnableNavbar,
        navbarEnabled: false,
      });

      appDispatch({
        type: AppActionType.UpdateIsAiGenMode,
        isAiGenMode: true,
      });

      appDispatch({
        type: AppActionType.UpdateIsAiForm,
        isAiForm: false,
      });

      setDisableScroll(true);
    };

    const onAiCreateComplete = () => {
      console.warn('>>> onAiCreateComplete.');
      // todo: update bottomNavbarAI UI
    };

    const onShowAiFormCallback = (enabled: boolean) => {
      console.warn('>>> onShowAiFormCallback.');
      appDispatch({
        type: AppActionType.UpdateIsAiForm,
        isAiForm: enabled,
      });
    };

    mainSuite.navbarService.on('CAI_IS_HERE', viewerCallback);
    mainSuite.navbarService.on('STORY_READY', storyReadyCallback);
    mainSuite.navbarService.on('BUY_GEM', buyGemCallback);
    mainSuite.navbarService.on('STORY_END', storyEndCallback);
    mainSuite.navbarService.on('STORY_SCROLL', storyScrollCallback);
    mainSuite.navbarService.on('FRONTEND_SCREENSHOT', frontendScreenshotCallback);
    mainSuite.navbarService.on('SHOW_UI', storyShowUICallback);
    mainSuite.navbarService.on('GENERATE_AI_STORY', onAiCreateStoryStart);
    mainSuite.navbarService.on('GENERATE_AI_STORY_COMPLETE', onAiCreateComplete);
    mainSuite.navbarService.on('SHOW_AI_FORM', onShowAiFormCallback);

    return () => {
      mainSuite.navbarService.off('CAI_IS_HERE', viewerCallback);
      mainSuite.navbarService.off('STORY_READY', storyReadyCallback);
      mainSuite.navbarService.off('BUY_GEM', buyGemCallback);
      mainSuite.navbarService.off('STORY_END', storyEndCallback);
      mainSuite.navbarService.off('STORY_SCROLL', storyScrollCallback);
      mainSuite.navbarService.off('FRONTEND_SCREENSHOT', frontendScreenshotCallback);
      mainSuite.navbarService.off('SHOW_UI', storyShowUICallback);
      mainSuite.navbarService.off('GENERATE_AI_STORY', onAiCreateStoryStart);
      mainSuite.navbarService.off('GENERATE_AI_STORY_COMPLETE', onAiCreateComplete);
      mainSuite.navbarService.off('SHOW_AI_FORM', onShowAiFormCallback);
    };
  }, [currentFeedItem, gemzState.feed, frontendScreenshotUrl, disableScroll, updateFeed]);

  // load story
  useEffect(() => {
    if (!feed?.length) {
      return;
    }
    if (!viewerReady) {
      return;
    }
    if (!currentFeedItem) {
      return;
    }
    if (currentSlide === -1) {
      return;
    }

    // TODO: currently, game automatically hides AI prompt when PLAY_STORY event is sent,
    //       so we're preventing normal load for now
    if (showCreate) {
      preloadStories(currentSlide);
      return;
    }

    // load first slide
    // if (showCreate && currentSlide !== 0) {
    //   return;
    // }

    console.log({ currentFeedItem });

    let stale = false;
    const loadStory = async () => {
      const storyData = await loadStoryFromGem(currentFeedItem);

      if (stale) {
        return;
      }

      setGameLoadingState('debouncing');

      playStory({ currentEntry: currentFeedItem, storyData });
    };

    console.warn('>>> website - loading story data...');

    loadStory().then(() => {
      if (stale) {
        return;
      }
      preloadStories(currentSlide);
    });

    return () => {
      stale = true;
    };
  }, [viewerReady, currentFeedItem, currentSlide, feed, showCreate, preloadStories]);

  // play the story once it has been loaded,
  // and only if the carousel is not in the middle of changing the slide
  const playStory = useMemo(
    () =>
      debounce(({ currentEntry, storyData }) => {
        console.log('>>> storyData', { storyData });

        setGameLoadingState('loading ' + currentEntry.id);

        mainSuite.navbarService.sendClientEvent('SET_GEM', {
          image: currentEntry.gemimage,
          name: currentEntry.gemname,
          id: currentEntry.id,
        });

        mainSuite.navbarService.sendClientEvent('PLAY_STORY', storyData);

        // sending message to the game for it to figure out what content to preload
        if (currentFeedItem) {
          mainSuite.navbarService.sendClientEvent('SCROLL_FEED', { currentFeedItem, feed, scrollDirection });
        }
      }, 500),
    [currentFeedItem, feed, scrollDirection],
  );

  // todo(carles/jb)
  // todo: when we use carousel by internal swiping functionality, slides are seamless and infinite
  // todo: but when we use our custom stuff, slides dont behave seamless. we need to figure out a solution for this.

  const goToNextSlide = useMemo(
    () =>
      debounce(
        () => {
          setScrollDirection(1);
          carouselRef.current?.increment();
        },
        700,
        true,
      ),
    [carouselRef],
  );

  const goToPrevSlide = useMemo(
    () =>
      debounce(
        () => {
          setScrollDirection(-1);
          carouselRef.current?.decrement();
        },
        700,
        true,
      ),
    [carouselRef],
  );

  const doScrollCustom = (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
    // console.warn('>>> doCustomScroll', disableScroll);
    if (disableScroll) {
      return;
    }

    if (!carouselRef.current) {
      return;
    }

    if (Math.abs(opts.deltaY) < scrollDeltaThreshold) {
      return;
    }
    let deltaY = opts.deltaY;
    if (opts.webkitDirectionInvertedFromDevice) {
      deltaY = -deltaY;
    }
    const { selectedItem, itemSize } = carouselRef.current.state;
    if (opts.deltaY < -scrollDeltaThreshold && selectedItem < itemSize - 1) {
      // we want the slides to be infinite and seamless
      goToNextSlide();
    }
    if (opts.deltaY > scrollDeltaThreshold && selectedItem > 0) {
      // we want the slides to be infinite and seamless
      goToPrevSlide();
    }
  };

  // handle scroll events for carousel
  const doScroll = useCallback(
    (e: WheelEvent) => {
      // console.warn('>>> doScroll', disableScroll);
      if (disableScroll) {
        return;
      }
      doScrollCustom({
        deltaY: e.deltaY,
        webkitDirectionInvertedFromDevice: (e as any).webkitDirectionInvertedFromDevice,
      });
    },
    [carouselRef, goToNextSlide, goToPrevSlide, disableScroll],
  );

  // listen to scroll events
  useEffect(() => {
    if (!viewerReady) {
      return;
    }

    window.addEventListener('wheel', doScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('wheel', doScroll, {
        capture: false,
      });
    };
  }, [doScroll, viewerReady]);

  // replace current URL
  useEffect(() => {
    if (!appState.initialSearchParams) {
      return;
    }
    if (!currentFeedItem) {
      return;
    }
    if (showCreate) {
      return;
    }

    let url = getPagePath(currentFeedItem.gemname);

    if (appState.initialSearchParams.size) {
      url += '?' + appState.initialSearchParams.toString();
    }

    window.history.replaceState({}, null, url);
  }, [currentFeedItem, appState.initialSearchParams, showCreate]);

  // game URL
  const gameUrl = useMemo(() => {
    if (!appState.initialSearchParams) {
      return null;
    }
    const hostname = window.location.hostname;
    if (hostname.includes('gem-dev') || appState.initialSearchParams.has('gemDevUrl')) {
      return 'https://d70g6vzrdmee5.cloudfront.net/ver-carles-viewer/index.html?iframed=true';
    }
    if (hostname.includes('localhost') && !appState.initialSearchParams.has('gemUrl')) {
      return 'http://localhost:3002?iframed=true';
    }
    return 'https://d70g6vzrdmee5.cloudfront.net/ver-gemz-viewer/index.html?iframed=true';
  }, [appState.initialSearchParams]);

  const loaded = Boolean(gameUrl && gemzState.feedState === LoadState.Loaded && currentFeedItem);

  console.log({
    feed,
    currentFeedItem,
    loaded,
  });

  return (
    <Page className="home-base" title="Collectible Stories" introBg fixedNavbar={true}>
      <MintHomeLayout className="home-state-page-layout" title="Storyverse" subtitle={`Collect & play to watch your story unfold`}>
        {console.error('>>> isAiForm', appState.isAiForm, 'isAiGenMode', appState.isAiGenMode, 'showGame', appState.showGame)}

        {loaded && !appState.isAiForm && !appState.isAiGenMode && (
          <Carousel
            ref={carouselRef}
            autoFocus={true}
            axis={'vertical'}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={false} // disabled to avoid scroller jumping craziness
            dynamicHeight={false}
            // autoplay
            autoPlay={false}
            interval={4000}
            stopOnHover={false}
            // swiping
            swipeable={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={5} // default is 5
            verticalSwipe={'standard'} // standard or natural -> natural is inverse direction
            emulateTouch={true}
            transitionTime={500}
            animationHandler={'slide'} // 'slide' | 'fade' | AnimationHandler;
            useKeyboardArrows={true}
            // selecting
            // selectedItem={currentSlideRef}
            onChange={(index) => {
              // hide game whenever we start changing the carousel slide
              appDispatch({
                type: AppActionType.GameIsPlaying,
                showGame: false,
              });

              // note: debouncer takes care of this. leaving it on for reference
              // set flag while carousel is changing slide
              // setIsChangingSlide(true);
              // window.setTimeout(() => setIsChangingSlide(false), 500);

              // establish slide to change to
              setCurrentSlide(index);
            }}
          >
            {(feed || []).map((feedItem, index) => (
              <Item key={feedItem.id} gemimage={frontendScreenshotUrl || feedItem.story_image || feedItem.gemimage} onClick={() => {}} />
            ))}
          </Carousel>
        )}

        {loaded && (
          <ItemInfo
            debug={{ currentSlide, viewerReady, gameLoadingState, disableScroll }}
            gemz={currentFeedItem}
            enabled={!appState.showOnboard && !appState.isAiGenMode && !appState.isAiForm}
            bottomInfoEnabled={showUI}
            key={currentFeedItem.id}
            type={'live'}
            onClick={() => {}}
          />
        )}
      </MintHomeLayout>

      {currentFeedItem && (
        <div className={`story-game ${appState.showGame ? 'enabled' : 'disabled'}`}>
          <HostedApp src={gameUrl} style={{ position: 'absolute' }} />;
        </div>
      )}

      {appState.isAiForm && <AiCreateForm />}

      {!appState.isAiForm && !appState.isAiGenMode && <TopBar />}

      {!loaded && <TransitioningSection fixed={true} />}
    </Page>
  );
}

// ======================================================================================================

const getFormDataParams = (fields: Record<string, string>, file: File) => {
  const formData = new FormData();
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);
  return {
    method: 'POST',
    body: formData,
  };
};

const uploadFileWithSignedPost = async ({ b64, key }: { b64: string; key: string }) => {
  const res: Response = await fetch(b64);
  const blob: Blob = await res.blob();
  const file = new File([blob], 'image.jpg', { type: 'image/jpg' });

  let signedPostData: any;
  console.log('uploadFileWithSignedPost', { key });
  try {
    const response = await fetch(`https://pipeline.beta.pnk.one/gems/minted/img/${key}`);
    signedPostData = await response.json();
    const uploadResponse = await fetch(signedPostData.url, getFormDataParams(signedPostData.fields, file));
    if (!uploadResponse.ok) {
      throw new Error(uploadResponse.statusText);
    }

    const uploadedImageUrl = `https://media.pnk.one/gemz/${key}`;

    return uploadedImageUrl;
  } catch (e) {
    console.log(`Error (uploadFileWithSignedPost):`, e);
    throw new Error(`Error: Could not sign post for '${key}'.`);
  }
};

const screenshotQueued: Record<string, boolean> = {};
const queueScreenshot = async (gemData: Pick<GemzItem, 'id' | 'story_image' | 'creator_address' | 'story_index' | 'username'>, force = false) => {
  log(`Queuing screenshot for ${gemData.id}...`);
  if (!force && (screenshotQueued[gemData.id] || gemData.story_image)) {
    return;
  }

  const response = await fetch('https://pipeline.beta.pnk.one/gems/renderThumbnail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        storyKey: `${gemData.creator_address}/${gemData.story_index}`,
        gemzId: gemData.id,
        gemzUsername: gemData.username,
      },
    }),
  });

  if (!response.ok) {
    const text = await response.text();
    const error = new Error(`queueScreenshot error: ${text}`);
    log(error); // so sentry can pick it up
    console.error(error); // make error visibly public in console
    return; // failing silently for frontend
  }

  screenshotQueued[gemData.id] = true;

  console.warn('>>> website - server side screenshot was queued successfully');

  log(`Queued screenshot for ${gemData.id}`);
};

// import { Page } from '../Page';
// import './MintHomePage.scss';
// import '../mint-v2/components/CollectionsForm.scss';

// import MintHomeLayout from './layouts/MintHomeLayout';
// import { Carousel } from 'react-responsive-carousel';
// import Item from './components/Item';

// import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
// import { TransitioningSection } from '@components/transitioning-section';
// import { mainSuite } from '@services/ServiceFactory';
// import { debug } from '@common/LogWrapper';
// import ItemInfo from './components/ItemInfo';
// import { HostedApp } from '@components/hosted-app/HostedApp';
// import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';

// import { useNavigate, useParams } from 'react-router-dom';
// import debounce from '@common/Debounce';
// import { PathParam } from '@common/PathParam';
// import { GemzItem, getPagePath } from '@common/GemzItem';
// import { useGemz } from '@hooks/useGemz';
// import { GemzActionType } from '../../context/gemz/GemzActionType';
// import { LoadState } from '../../common/LoadState';
// import TopBar from './components/TopBar';
// import { SearchParam } from '@common/SearchParam';
// import { AiCreateForm } from '../create/AiCreateForm';

// const log = debug('app:pages:MintHomePage');

// const scrollDeltaThreshold = 10;

// enum State {
//   VIEWER_LOADING,
//   LOADING_STORY_DATA,
//   SEND_STORY_DATA_TO_GAME,
//   WAIT_FOR_GAME_LOAD,
// }

// export function MintHomePage({ showCreate = false }: { showCreate?: boolean }) {
//   const navigate = useNavigate();

//   const appDispatch = useAppDispatch();

//   const { gemzState, updateFeed, gemzDispatch, loadStoryFromGem, preloadStories } = useGemz();

//   const appState = useAppState();

//   const params = useParams();

//   const [showUI, setShowUI] = useState(true);

//   const [currentSlide, setCurrentSlide] = useState(0);

//   const [scrollDirection, setScrollDirection] = useState(0);

//   // note: debouncer takes care of this. leaving it on for reference
//   // const [isChangingSlide, setIsChangingSlide] = useState(false);

//   const carouselRef = useRef<Carousel>();

//   const [viewerReady, setViewerReady] = useState(false);

//   const feed = gemzState.feed;
//   const [disableScroll, setDisableScroll] = useState(appState.isAiGenMode || appState.isAiForm);

//   const [frontendScreenshotUrl, setFrontendScreenshotUrl] = useState<string>();

//   const [gameLoadingState, setGameLoadingState] = useState<string>('none');

//   useEffect(() => {
//     if (!appState.initialSearchParams) {
//       return;
//     }
//     if (appState.showOnboard) {
//       const urlSearchParams = new URLSearchParams(appState.initialSearchParams);
//       if (showCreate) {
//         urlSearchParams.append(SearchParam.Redirect, `/create?${appState.initialSearchParams.toString()}`);
//       } else if (params[PathParam.SalePath]) {
//         urlSearchParams.append(SearchParam.Redirect, `/${params[PathParam.SalePath]}?${appState.initialSearchParams.toString()}`);
//       }
//       if (urlSearchParams.size) {
//         navigate(`/?${urlSearchParams.toString()}`);
//       } else {
//         navigate('/');
//       }
//     }
//   }, [appState.initialSearchParams, appState.showOnboard, showCreate]);

//   // Load feed
//   useEffect(() => {
//     const fetchFeed = async () => {
//       await updateFeed();
//       if (params?.[PathParam.SalePath]) {
//         gemzDispatch({
//           type: GemzActionType.BringGemToTop,
//           gemPath: `/${params[PathParam.SalePath]}`,
//         });
//       }
//       setCurrentSlide(0);
//     };

//     fetchFeed();
//   }, [params, updateFeed]);

//   const currentFeedItem: GemzItem | undefined = feed?.[currentSlide];

//   // Add viewer callback
//   useEffect(() => {
//     const viewerCallback = async () => {
//       console.warn('Viewer is ready');
//       setViewerReady(true);
//     };

//     const storyReadyCallback = (gemData: { imageUrl: string; name: string; id: string }) => {
//       // if the carousel is scrolling the iframe should just deny the request to show the game, so iframe stays hidden
//       // if the carousel is on another story, denies too
//       // if the carousel in on the game spot, accepts and shows the carousel

//       setGameLoadingState('init ' + gemData.id + ' / ' + currentFeedItem.id);

//       // current slide info
//       // console.warn('>>> currentFeedItem.gemname', currentFeedItem.gemname, '-> gemName', opts.gemName);
//       if (gemData.id !== currentFeedItem.id) {
//         console.error('>>> game gem does not match website item. Avoiding to display game.');
//         // setGameLoadingState('abort because mismatch');
//         return;
//       }

//       if (carouselRef.current) {
//         if (carouselRef.current.state.swipeMovementStarted || carouselRef.current.state.swiping) {
//           console.error('>>> carousel is swiping. Avoiding to display game.');
//           setGameLoadingState('abort because sliding');
//           return;
//         }
//       }

//       // note: debouncer will take care of this. otherwise game will never load
//       // if (isChangingSlide) {
//       //   console.error('>>> carousel is changing slide. Avoiding to display game.');
//       //   return;
//       // }

//       console.warn('>>> website - storyReady. displaying game', gemData);

//       // DEBUG: generate screenshot after story is loaded
//       // queueScreenshot(currentFeedItem);

//       appDispatch({
//         type: AppActionType.GameIsPlaying,
//         showGame: true,
//       });

//       setDisableScroll(appState.isAiGenMode || appState.isAiForm);

//       setGameLoadingState('playing ' + gemData.id);
//     };

//     const storyEndCallback = () => {
//       console.warn('>>> storyEnd');

//       // --------------------------------
//       // AI generation game ended
//       if (appState.isAiGenMode) {
//         // todo carles; navigate to feed after escaping ai generation mode
//         updateFeed().then(() => {
//           setDisableScroll(false);

//           appDispatch({
//             type: AppActionType.ToggleEnableNavbar,
//             navbarEnabled: true,
//           });

//           appDispatch({
//             type: AppActionType.UpdateIsAiGenMode,
//             isAiGenMode: false,
//           });

//           // todo: ideally, we should move to next slide,
//           // todo: but if we do not repload the page for some reason stories wont load anymore
//           navigate('/');

//           //  auto-navigate to next slide after we bough the story gem
//           // if (currentSlide < feed.length - 1) {
//           //   goToNextSlide();
//           // } else {
//           //   goToPrevSlide();
//           // }

//           return;
//         });
//       }

//       // --------------------------------
//       // Normal game ended

//       appDispatch({
//         type: AppActionType.GameIsPlaying,
//         showGame: false,
//       });
//       //  auto-navigate to next slide after we bough the story gem
//       if (currentSlide < feed.length - 1) {
//         goToNextSlide();
//       } else {
//         goToPrevSlide();
//       }
//     };

//     const storyScrollCallback = async (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
//       // console.warn('>>> storyScrollCallback', disableScroll);
//       if (disableScroll) {
//         return;
//       }
//       doScrollCustom(opts);
//       setShowUI(true); // always show ui while scrolling
//     };

//     const frontendScreenshotCallback = async (screenshot: string) => {
//       // note: this method will only be called if we set
//       // frontendScreenshotEnabled=true in game's GemzController

//       if (!screenshot) {
//         return;
//       }

//       // For testing purposes, lets add last frontend screenshot to all slides
//       setFrontendScreenshotUrl(screenshot);
//     };

//     // todo(Cai): we should use gem unique ids for all gem related transactions
//     const buyGemCallback = async ({ story, gemData, correctChoices }) => {
//       await mainSuite.navbarService.api.signSession();

//       const wallet = await mainSuite.navbarService.api.getWallet();
//       if (!wallet) {
//         console.error(`Cannot buy gem without 'wallet'.`);
//         return;
//       }
//       const twitter = await mainSuite.navbarService.api.twitterService.auth.get();
//       if (!twitter) {
//         console.error(`Cannot buy gem without 'twitter'.`);
//         return;
//       }
//       try {
//         const creatorFeed = gemzState.feed.filter((item) => item.creator_address.toLowerCase() === wallet.address.toLowerCase());

//         const mintData = {
//           data: {
//             creatorAddress: appState.isAiGenMode ? wallet.address : currentFeedItem.creator_address,
//             storyId: appState.isAiGenMode ? creatorFeed.length : currentFeedItem.story_index,
//             ownerAddress: wallet.address,
//             txhash: 'txhash',
//             confirmed: false,
//             username: twitter.handle,
//             gemName: appState.isAiGenMode ? gemData.name : currentFeedItem.gemname,
//             storyData: encodeURI(JSON.stringify(story)),
//             // todo carles/jb: at this point, in isAiGenMode mode, the story we are creating has not been added to the feed
//             story_image: appState.isAiGenMode ? '' : currentFeedItem.story_image, // appState.isAiGenMode ? frontendScreenshotUrl : currentFeedItem.story_image,
//             correctChoices,
//           },
//         };

//         const response = await fetch('https://pipeline.beta.pnk.one/gems/minted', {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify(mintData),
//         });

//         if ((await response.json()).error) {
//           throw new Error('Something went wrong!');
//         }

//         console.warn('>>> https://pipeline.beta.pnk.one/gems/minted', response, mintData);

//         // send purchase outcome back to the game/viewer
//         mainSuite.navbarService.sendClientEvent('PURCHASE', 'success');
//       } catch (e) {
//         console.log(e);
//         mainSuite.navbarService.sendClientEvent('PURCHASE', 'error');
//       }
//     };

//     const storyShowUICallback = async (enabled: boolean) => {
//       setShowUI(enabled);
//     };

//     const onAiCreateStoryStart = () => {
//       console.warn('>>> onAiCreateStoryStart. disabling scroll');
//       appDispatch({
//         type: AppActionType.ToggleEnableNavbar,
//         navbarEnabled: false,
//       });

//       appDispatch({
//         type: AppActionType.UpdateIsAiGenMode,
//         isAiGenMode: true,
//       });

//       appDispatch({
//         type: AppActionType.UpdateIsAiForm,
//         isAiForm: false,
//       });

//       setDisableScroll(true);
//     };

//     const onAiCreateComplete = () => {
//       console.warn('>>> onAiCreateComplete.');
//       // todo: update bottomNavbarAI UI
//     };

//     const onShowAiFormCallback = (enabled: boolean) => {
//       console.warn('>>> onShowAiFormCallback.');
//       appDispatch({
//         type: AppActionType.UpdateIsAiForm,
//         isAiForm: enabled,
//       });
//     };

//     mainSuite.navbarService.on('CAI_IS_HERE', viewerCallback);
//     mainSuite.navbarService.on('STORY_READY', storyReadyCallback);
//     mainSuite.navbarService.on('BUY_GEM', buyGemCallback);
//     mainSuite.navbarService.on('STORY_END', storyEndCallback);
//     mainSuite.navbarService.on('STORY_SCROLL', storyScrollCallback);
//     mainSuite.navbarService.on('FRONTEND_SCREENSHOT', frontendScreenshotCallback);
//     mainSuite.navbarService.on('SHOW_UI', storyShowUICallback);
//     mainSuite.navbarService.on('GENERATE_AI_STORY', onAiCreateStoryStart);
//     mainSuite.navbarService.on('GENERATE_AI_STORY_COMPLETE', onAiCreateComplete);
//     mainSuite.navbarService.on('SHOW_AI_FORM', onShowAiFormCallback);

//     return () => {
//       mainSuite.navbarService.off('CAI_IS_HERE', viewerCallback);
//       mainSuite.navbarService.off('STORY_READY', storyReadyCallback);
//       mainSuite.navbarService.off('BUY_GEM', buyGemCallback);
//       mainSuite.navbarService.off('STORY_END', storyEndCallback);
//       mainSuite.navbarService.off('STORY_SCROLL', storyScrollCallback);
//       mainSuite.navbarService.off('FRONTEND_SCREENSHOT', frontendScreenshotCallback);
//       mainSuite.navbarService.off('SHOW_UI', storyShowUICallback);
//       mainSuite.navbarService.off('GENERATE_AI_STORY', onAiCreateStoryStart);
//       mainSuite.navbarService.off('GENERATE_AI_STORY_COMPLETE', onAiCreateComplete);
//       mainSuite.navbarService.off('SHOW_AI_FORM', onShowAiFormCallback);
//     };
//   }, [currentFeedItem, gemzState.feed, frontendScreenshotUrl, disableScroll, updateFeed]);

//   // load story
//   useEffect(() => {
//     if (!feed?.length) {
//       return;
//     }
//     if (!viewerReady) {
//       return;
//     }
//     if (!currentFeedItem) {
//       return;
//     }
//     if (currentSlide === -1) {
//       return;
//     }

//     // TODO: currently, game automatically hides AI prompt when PLAY_STORY event is sent,
//     //       so we're preventing normal load for now
//     if (showCreate) {
//       preloadStories(currentSlide);
//       return;
//     }

//     // load first slide
//     // if (showCreate && currentSlide !== 0) {
//     //   return;
//     // }

//     console.log({ currentFeedItem });

//     let stale = false;
//     const loadStory = async () => {
//       const storyData = await loadStoryFromGem(currentFeedItem);

//       setGameLoadingState('stale-check');

//       if (stale) {
//         return;
//       }

//       setGameLoadingState('debouncing');

//       playStory({ currentEntry: currentFeedItem, storyData });
//     };

//     console.warn('>>> website - loading story data...');

//     setGameLoadingState('loading');

//     loadStory().then(() => {
//       if (stale) {
//         return;
//       }
//       preloadStories(currentSlide);
//     });

//     return () => {
//       stale = true;
//     };
//   }, [viewerReady, currentFeedItem, currentSlide, feed, showCreate, preloadStories]);

//   // play the story once it has been loaded,
//   // and only if the carousel is not in the middle of changing the slide
//   const playStory = useMemo(
//     () =>
//       debounce(({ currentEntry, storyData }) => {
//         console.log('>>> storyData', { storyData });

//         setGameLoadingState('loading ' + currentEntry.id);

//         mainSuite.navbarService.sendClientEvent('SET_GEM', {
//           image: currentEntry.gemimage,
//           name: currentEntry.gemname,
//           id: currentEntry.id,
//         });

//         // setGameLoadingState('loading');

//         mainSuite.navbarService.sendClientEvent('PLAY_STORY', storyData);

//         // sending message to the game for it to figure out what content to preload
//         if (currentFeedItem) {
//           mainSuite.navbarService.sendClientEvent('SCROLL_FEED', { currentFeedItem, feed, scrollDirection });
//         }
//       }, 500),
//     [currentFeedItem, feed, scrollDirection],
//   );

//   // todo(carles/jb)
//   // todo: when we use carousel by internal swiping functionality, slides are seamless and infinite
//   // todo: but when we use our custom stuff, slides dont behave seamless. we need to figure out a solution for this.

//   const goToNextSlide = useMemo(
//     () =>
//       debounce(
//         () => {
//           setScrollDirection(1);
//           carouselRef.current?.increment();
//         },
//         700,
//         true,
//       ),
//     [carouselRef],
//   );

//   const goToPrevSlide = useMemo(
//     () =>
//       debounce(
//         () => {
//           setScrollDirection(-1);
//           carouselRef.current?.decrement();
//         },
//         700,
//         true,
//       ),
//     [carouselRef],
//   );

//   const doScrollCustom = (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
//     // console.warn('>>> doCustomScroll', disableScroll);
//     if (disableScroll) {
//       return;
//     }

//     if (!carouselRef.current) {
//       return;
//     }

//     if (Math.abs(opts.deltaY) < scrollDeltaThreshold) {
//       return;
//     }
//     let deltaY = opts.deltaY;
//     if (opts.webkitDirectionInvertedFromDevice) {
//       deltaY = -deltaY;
//     }
//     const { selectedItem, itemSize } = carouselRef.current.state;
//     if (opts.deltaY < -scrollDeltaThreshold && selectedItem < itemSize - 1) {
//       // we want the slides to be infinite and seamless
//       goToNextSlide();
//     }
//     if (opts.deltaY > scrollDeltaThreshold && selectedItem > 0) {
//       // we want the slides to be infinite and seamless
//       goToPrevSlide();
//     }
//   };

//   // handle scroll events for carousel
//   const doScroll = useCallback(
//     (e: WheelEvent) => {
//       // console.warn('>>> doScroll', disableScroll);
//       if (disableScroll) {
//         return;
//       }
//       doScrollCustom({
//         deltaY: e.deltaY,
//         webkitDirectionInvertedFromDevice: (e as any).webkitDirectionInvertedFromDevice,
//       });
//     },
//     [carouselRef, goToNextSlide, goToPrevSlide, disableScroll],
//   );

//   // listen to scroll events
//   useEffect(() => {
//     if (!viewerReady) {
//       return;
//     }

//     window.addEventListener('wheel', doScroll, {
//       capture: false,
//       passive: true,
//     });

//     return () => {
//       window.removeEventListener('wheel', doScroll, {
//         capture: false,
//       });
//     };
//   }, [doScroll, viewerReady]);

//   // replace current URL
//   useEffect(() => {
//     if (!appState.initialSearchParams) {
//       return;
//     }
//     if (!currentFeedItem) {
//       return;
//     }
//     if (showCreate) {
//       return;
//     }

//     let url = getPagePath(currentFeedItem.gemname);

//     if (appState.initialSearchParams.size) {
//       url += '?' + appState.initialSearchParams.toString();
//     }

//     window.history.replaceState({}, null, url);
//   }, [currentFeedItem, appState.initialSearchParams, showCreate]);

//   // game URL
//   const gameUrl = useMemo(() => {
//     if (!appState.initialSearchParams) {
//       return null;
//     }
//     const hostname = window.location.hostname;
//     if (hostname.includes('gem-dev') || appState.initialSearchParams.has('gemDevUrl')) {
//       return 'https://d70g6vzrdmee5.cloudfront.net/ver-carles-viewer/index.html?iframed=true';
//     }
//     if (hostname.includes('localhost') && !appState.initialSearchParams.has('gemUrl')) {
//       return 'http://localhost:3002?iframed=true';
//     }
//     return 'https://d70g6vzrdmee5.cloudfront.net/ver-gemz-viewer/index.html?iframed=true';
//   }, [appState.initialSearchParams]);

//   const loaded = Boolean(gameUrl && gemzState.feedState === LoadState.Loaded && currentFeedItem);

//   console.log({
//     feed,
//     currentFeedItem,
//     loaded,
//   });

//   return (
//     <Page className="home-base" title="Collectible Stories" introBg fixedNavbar={true}>
//       <MintHomeLayout className="home-state-page-layout" title="Storyverse" subtitle={`Collect & play to watch your story unfold`}>
//         {/* {console.error('>>> isAiForm', appState.isAiForm, 'isAiGenMode', appState.isAiGenMode, 'showGame', appState.showGame, 'disableScroll', disableScroll)} */}

//         {loaded && !appState.isAiForm && !appState.isAiGenMode && (
//           <Carousel
//             ref={carouselRef}
//             autoFocus={true}
//             axis={'vertical'}
//             showThumbs={false}
//             showStatus={false}
//             showIndicators={false}
//             infiniteLoop={false} // disabled to avoid scroller jumping craziness
//             dynamicHeight={false}
//             // autoplay
//             autoPlay={false}
//             interval={4000}
//             stopOnHover={false}
//             // swiping
//             swipeable={true}
//             preventMovementUntilSwipeScrollTolerance={true}
//             swipeScrollTolerance={5} // default is 5
//             verticalSwipe={'standard'} // standard or natural -> natural is inverse direction
//             emulateTouch={true}
//             transitionTime={500}
//             animationHandler={'slide'} // 'slide' | 'fade' | AnimationHandler;
//             useKeyboardArrows={true}
//             // selecting
//             // selectedItem={currentSlideRef}
//             onChange={(index) => {
//               // hide game whenever we start changing the carousel slide
//               appDispatch({
//                 type: AppActionType.GameIsPlaying,
//                 showGame: false,
//               });

//               // note: debouncer takes care of this. leaving it on for reference
//               // set flag while carousel is changing slide
//               // setIsChangingSlide(true);
//               // window.setTimeout(() => setIsChangingSlide(false), 500);

//               // establish slide to change to
//               setCurrentSlide(index);
//             }}
//           >
//             {(feed || []).map((feedItem, index) => (
//               <Item key={feedItem.id} gemimage={frontendScreenshotUrl || feedItem.story_image || feedItem.gemimage} onClick={() => {}} />
//             ))}
//           </Carousel>
//         )}

//         {loaded && (
//           <ItemInfo
//             debug={{ currentSlide, viewerReady, gameLoadingState, disableScroll }}
//             gemz={currentFeedItem}
//             enabled={!appState.showOnboard && !appState.isAiGenMode && !appState.isAiForm}
//             bottomInfoEnabled={showUI}
//             key={currentFeedItem.id}
//             type={'live'}
//             onClick={() => {}}
//           />
//         )}
//       </MintHomeLayout>

//       {currentFeedItem && (
//         <div className={`story-game ${appState.showGame ? 'enabled' : 'disabled'}`}>
//           <HostedApp src={gameUrl} style={{ position: 'absolute' }} />;
//         </div>
//       )}

//       {/* {currentFeedItem && (
//         <div className={`story-game enabled`}>
//           <HostedApp src={gameUrl} style={{ position: 'absolute' }} />;
//         </div>
//       )} */}

//       {appState.isAiForm && <AiCreateForm />}
//       {!appState.isAiForm && !appState.isAiGenMode && <TopBar />}
//       {!loaded && <TransitioningSection fixed={true} />}
//     </Page>
//   );
// }

// // ======================================================================================================

// const getFormDataParams = (fields: Record<string, string>, file: File) => {
//   const formData = new FormData();
//   Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
//   formData.append('file', file);
//   return {
//     method: 'POST',
//     body: formData,
//   };
// };

// const uploadFileWithSignedPost = async ({ b64, key }: { b64: string; key: string }) => {
//   const res: Response = await fetch(b64);
//   const blob: Blob = await res.blob();
//   const file = new File([blob], 'image.jpg', { type: 'image/jpg' });

//   let signedPostData: any;
//   console.log('uploadFileWithSignedPost', { key });
//   try {
//     const response = await fetch(`https://pipeline.beta.pnk.one/gems/minted/img/${key}`);
//     signedPostData = await response.json();
//     const uploadResponse = await fetch(signedPostData.url, getFormDataParams(signedPostData.fields, file));
//     if (!uploadResponse.ok) {
//       throw new Error(uploadResponse.statusText);
//     }

//     const uploadedImageUrl = `https://media.pnk.one/gemz/${key}`;

//     return uploadedImageUrl;
//   } catch (e) {
//     console.log(`Error (uploadFileWithSignedPost):`, e);
//     throw new Error(`Error: Could not sign post for '${key}'.`);
//   }
// };

// const screenshotQueued: Record<string, boolean> = {};
// const queueScreenshot = async (gemData: Pick<GemzItem, 'id' | 'story_image' | 'creator_address' | 'story_index' | 'username'>, force = false) => {
//   log(`Queuing screenshot for ${gemData.id}...`);
//   if (!force && (screenshotQueued[gemData.id] || gemData.story_image)) {
//     return;
//   }

//   const response = await fetch('https://pipeline.beta.pnk.one/gems/renderThumbnail', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       data: {
//         storyKey: `${gemData.creator_address}/${gemData.story_index}`,
//         gemzId: gemData.id,
//         gemzUsername: gemData.username,
//       },
//     }),
//   });

//   if (!response.ok) {
//     const text = await response.text();
//     const error = new Error(`queueScreenshot error: ${text}`);
//     log(error); // so sentry can pick it up
//     console.error(error); // make error visibly public in console
//     return; // failing silently for frontend
//   }

//   screenshotQueued[gemData.id] = true;

//   console.warn('>>> website - server side screenshot was queued successfully');

//   log(`Queued screenshot for ${gemData.id}`);
// };
